import './App.css';
import {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';

const BASE_CYCLE = 6 * 360; //6 spins

const AUDIOS = [
  'overshoot.mp3',
  'default.mp3',
  'accelerate.mp3',
]

const CURVES = [
  { weight: 0, audio: 0, animation: '10s cubic-bezier(.59,1.25,.95,1)'    }, // overshoot
  { weight: 30, audio: 1, animation: '10s cubic-bezier(0,.57,.61,0.99)'    }, // default
  { weight: 10, audio: 2, animation: '10s cubic-bezier(1,.02,.74,.97)'     }, // accelerate
];

const PRIZES = [
  { name: 'carte', weight: 14 },
  { name: 'demo', weight: 25, curve: CURVES[0] },
  { name: 'megawin', weight: 0 },
  { name: 'megapack', weight: 7 },
  { name: 'pack_fortunato', weight: 6 },
  { name: 'stickers', weight: 17 },
  { name: 'taza', weight: 14 },
  { name: 'magnets', weight: 17 },
];

const PRIZE_ANGLE = (360/PRIZES.length);

const getRandomWeightedItem = items => {
  const total_weight = items.reduce((p,c) => p + c.weight, 0);  
  const number = Math.floor(Math.random() * total_weight);
  const { selected } = items.reduce(({ selected, total_weight }, { weight }, i) => {
    if (selected != null) return { selected, total_weight };
    const item_weight_min = total_weight;
    const item_weight_max = total_weight + weight;
    if (number >= item_weight_min && number < item_weight_max) return { selected: i, total_weight: item_weight_max };
    else return { selected, total_weight: item_weight_max };
  }, { selected: null, total_weight: 0 })
  return {...items[selected], index: selected};
}

function App() {
  const audio = useRef();
  useEffect(() => {
    audio.current.forEach(a => a.load());
  }, []);
  const [ prize, setPrize ] = useState();
  const [ next_demo, setNextDemo ] = useState();
  const [ spinning, setSpinning ] = useState();
  const [ total_cycle, setTotalCycle ] = useState(0);
  const [ curve, setCurve ] = useState(getRandomWeightedItem(CURVES));
 
  const doSpin = useCallback(() => {
    const prize = next_demo ? {...PRIZES[1], index: 1} : getRandomWeightedItem(PRIZES);
    const curve = prize.curve ?? getRandomWeightedItem(CURVES);
    audio.current[curve.audio].play();
    setCurve(curve.animation);
    setSpinning(true);
    const return_zero = ((360 * Math.ceil(total_cycle / 360)) - total_cycle) % 360;
    const cycles = (
      total_cycle + //Previous angle
      BASE_CYCLE + //Base cycles
      return_zero + //Return to start of wheel
      ((prize.index + 1) * PRIZE_ANGLE) - //Select prize
      Math.max(10, Math.min(PRIZE_ANGLE - 10, Math.floor(Math.random() * PRIZE_ANGLE))) //Random inside selected prize for appearance
    );
    setTotalCycle(cycles);
    setPrize(prize);
  }, [ total_cycle, next_demo ]);

  const onWheelTransitionEnd = useCallback(() => {
    setNextDemo(false);
    setSpinning(false);
  }, [ ]);
  
  return (
    <div style={{display: 'flex', height: '100%'}}>
      {AUDIOS.map((src, i) => (
        <audio ref={r => {if(!audio.current) audio.current = []; audio.current[i] = r}} src={src}/>
      ))}
      <div style={{
        display: 'flex',
        fontFamily: 'Comic Sans MS, sans',
        maxWidth: '20vw', textAlign: 'center', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <h1>La Walculette della fortuna</h1>
        <h3 style={{opacity: '0.5'}}><marquee>Truccata</marquee></h3>
      </div>
      <div style={{ flex: 1, width: '100%', gap: '1em', justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginTop: '3em', position: 'relative', display: 'flex', flexDirection: 'column', flex: 1, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <img style={{ top: '-60px', zIndex: 999, position: 'absolute' }} className="hand" alt="premio" src="hand.png" />
          <div style={{ position: 'relative', flex: 1, width: '100%', textAlign: 'center' }}>
            <img
              className="wheel"
              src="roulette_it.png"
              style={{
                userSelect: 'none',
                transform: `rotate(${total_cycle}deg)`,
                transition: `transform ${curve}`,

              }}
              onTransitionEnd={onWheelTransitionEnd}
              usemap="#image-map"
            />
            <div onClick={() => setNextDemo(d => !d)} style={{
              backgroundColor: next_demo ? 'rgba(0, 0, 0, 0.3)' : 'transparent',
              position: 'absolute',
              top: '50%',
              left: '50%',
              bottom: '50%',
              right: '50%',
              margin: '-9px',
              borderRadius: '50%',
              zIndex: 99,
            }}>
            </div>
          </div>
        </div>
        <div className="spin-btn" onClick={doSpin} style={spinning ? {
          boxShadow: "0 3px 1.5px #666",
          transform: "translate(0%, 14%)",
          pointerEvents: "none",
        } : {
          boxShadow: "0 5px 1.5px #999",
          transform: "translate(0%, 0%)",
          pointerEvents: "auto",
        }}>
          Gira la ruota!
        </div>
        <div
          style={{ visibility: spinning ? 'visible' : 'hidden' }}
          className={"reducted".concat(spinning ? " animate__animated animate__fadeOutUp" : "")}
        >
        </div>
      </div>
    </div>
  );
}

export default App;
